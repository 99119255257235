import React, { useState } from "react";
import axios from "axios";
import { InputGroup, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import logo from "./logo.jpg";

const PROXY = process.env.REACT_APP_URL + "api/";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [msg, setMsg] = useState("");

  const { token } = useParams();

  const loading = false;

  const handleValidation = () => {
    let formIsValid = true;

    if (password.length < 6) {
      formIsValid = false;
      setpasswordError("Password length must be minimum 6 Chracters");
      return false;
    } else if (confirmPassword === "") {
      formIsValid = false;
      setpasswordError("Please enter confirm password");
      return false;
    } else if (password !== confirmPassword) {
      formIsValid = false;
      setpasswordError("Confirm Password not matching");
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    const validate = handleValidation();

    const data = {
      password,
      confirm_pass: confirmPassword,
      token,
    };

    if (validate) {
      axios
        .post(PROXY + "user/reset-password", data)
        .then((res) => {
          setMsg(res?.data?.message);
        })
        .catch((err) => {
          console.log("err", err);
          setpasswordError(err.response.data.message);
        });

      setPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <div className="App">
      <div className="container">
        <div className="row d-flex justify-content-center align-item-center">
          {loading ? (
            <Spinner animation="border" className="mt-2" />
          ) : (
            <div className="col-md-4">
              <img
                src={logo}
                alt="App Logo"
                className="my-3"
                style={{ width: "310px", height: "70px" }}
              />
              <h4>Reset Password</h4>

              <form id="loginform" onSubmit={loginSubmit}>
                <div className="form-group">
                  <label>Password</label>
                  <InputGroup>
                    <input
                      type={showPass ? "text" : "password"}
                      className="form-control"
                      name="password"
                      placeholder="Enter password"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                      {showPass ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="form-group mt-2">
                  <label>Confirm Password</label>
                  <InputGroup>
                    <input
                      type={showConfirmPass ? "text" : "password"}
                      className="form-control"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                    />{" "}
                    <InputGroup.Text
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    >
                      {showConfirmPass ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}{" "}
                    </InputGroup.Text>
                  </InputGroup>

                  <small id="passworderror" className="text-danger form-text">
                    {passwordError}
                  </small>
                </div>
                {msg ? (
                  <small
                    id="passworderror"
                    className="text-success form-text mt-2"
                  >
                    {msg}
                  </small>
                ) : (
                  <button type="submit" className="btn themeBtn mt-3">
                    Submit
                  </button>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
