import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import logo from "./logo.jpg";

const PROXY = process.env.REACT_APP_URL + "api/";

const VerifyEmail = () => {
  const { token } = useParams();

  const [msg, setMsg] = useState("");

  const handleVerify = () => {
    axios
      .get(`${PROXY}user/verify_mail/${token}`)
      .then((res) => {
        setMsg(res?.data?.message);
      })
      .catch((err) => {
        console.log("err", err);
        setMsg(err.response.data.message);
      });
  };

  return (
    <div className="text-center mt-4">
      <img
        src={logo}
        alt="App Logo"
        className="my-3"
        style={{ width: "310px", height: "70px" }}
      />
      <h1>Verify registration</h1>
      {!msg ? (
        <button className="btn mt-3 themeBtn" onClick={handleVerify}>
          Verify
        </button>
      ) : (
        <h2>{msg}</h2>
      )}
    </div>
  );
};

export default VerifyEmail;
