import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import VerifyEmail from "./VerifyEmail";
import ResetPassword from "./ResetPassword";
import "./App.css";

const Loading = () => <div>Loading</div>;

const NotFound = () => (
  <div className="App">
    <h2>404 | Page Not Found</h2>
  </div>
);

const App = () => {
  return (
    <Router>
      <React.Suspense fallback={Loading()}>
        <Switch>
          <Route
            exact
            path="/verify/token/:token"
            name="Email Verification"
            render={(props) => <VerifyEmail {...props} />}
          />
          <Route
            exact
            path="/reset-password/token/:token"
            name="Reset Password"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default App;
